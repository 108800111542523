import { SubDomains } from '@/constants/subdomains';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

const REGEX_GROUPS = /(\d)(?=(\d\d\d)+(?!\d))/g;
const groupDigits = (
  value: string,
  delimiter = '&nbsp;',
) => value.replace(REGEX_GROUPS, `$1${delimiter}`);

type NumberFormatter = Intl.NumberFormat & {
  customFormat: (value: number) => string;
} | {
  customFormat: (value: number) => string;
  format: (value: number) => string;
};

export const useNumberFormatter = (
  options: Intl.NumberFormatOptions = {},
): NumberFormatter => {
  const { subDomain, language } = useSubDomainContext();

  // Locale should be in ISO 3166-1 alpha-2 format (e.g. en-US, en-GB)
  const domain = subDomain === SubDomains.usa
    ? 'us'
    : subDomain;

  try {
    const formatter = new Intl.NumberFormat(
      `${language}-${domain.toUpperCase()}`,
      {
        useGrouping: true,
        ...options,
      },
    );

    return Object.assign(formatter, {
      // https://stackoverflow.com/questions/66103296/is-intl-numberformat-formatting-correct-for-poland
      // with `useGrouping: true` it doesn't work for NodeJS & Polish language
      // NodeJS returns 1000 when browser returns 1 000
      // which brings inconsistency in SSR & CSR
      customFormat: (value: number) => {
        const result = formatter.format(value);

        const formatterOptions = formatter.resolvedOptions();

        if (formatterOptions.useGrouping) {
          return groupDigits(result);
        }

        return result;
      },
    });
  } catch {
    return {
      format: (value: number) => value.toString(),
      customFormat: (value: number) => value.toString(),
    };
  }
};
