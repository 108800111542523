import { FC } from 'react';
import { NextPage } from 'next';
import { LayoutOptions } from '@/typedefs/pageProps';
import { LayoutProps } from '@/components/layout/LayoutProps';
import { PageProps } from '@/controllers/page/page.typedefs';

export type WithLayoutResult<Props = PageProps> = (
  PageComponent: NextPage<Props>
) => NextPage<Props>;

export const withLayout = <Props extends PageProps = PageProps>(
  Layout: FC<LayoutProps>,
  layoutOptions?: LayoutOptions,
): WithLayoutResult<Props> => (PageComponent) => {
    Object.assign(
      PageComponent,
      { Layout, layoutOptions },
    );

    return PageComponent;
  };
